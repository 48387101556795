<template>
  <v-container
    class="payment-type payment-type-nexi ticket-type-restaurant px-0 px-sm-auto"
  >
    <div class="custom-warning">
      <v-alert
        v-if="reachMinimumPurchase"
        type="error"
        title="ATTENZIONE"
        class="wrn"
      >
        <span
          >E' possibile utilizzare buoni solo su spese superiori a
          {{ ticketData.minimumPurchase }}
          euro</span
        >
      </v-alert>
    </div>
    <span class="descr" v-html="paymentType.descr"></span>

    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-start mt-5" v-if="!ticketData.logged">
      <v-dialog
        v-model="dialog"
        v-if="isAuthenticated && paymentType.paymentTypeId == 21"
        :width="$vuetify.breakpoint.md ? '40%' : 'auto'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :class="[
              'ml-2 edenred-button',
              { 'white-background': paymentType.paymentTypeId == 21 }
            ]"
            v-on:click="login"
            depressed
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              style="width: 140px;"
              :src="
                '/img_layout/meal_ticket/btn_' +
                  paymentType.paymentTypeId +
                  '.svg'
              "
              :alt="'Logo Ticket Restaurant'"
            />
          </v-btn>
        </template>
        <div class="d-flex flex-column pellegrini-container" v-if="dialog">
          <v-row>
            <v-img
              class="pellegrini-logo"
              src="/img_layout/meal_ticket/21_login.svg"
              alt="Esegui il login Pellegrini"
            />
            <v-btn
              @click.stop="dialog = false"
              icon
              class="close modal-close-button-ticket"
              :aria-label="$t('common.close')"
            >
              <v-icon>$close</v-icon>
            </v-btn>
          </v-row>
          <div>
            <v-row>
              <v-col cols="6">
                <h4
                  style="font-size: 25px; color: #9e0005; font-weight: 500; text-transform: none;"
                >
                  Login
                </h4>
              </v-col>
              <v-col cols="6" class="logo-col">
                <v-img
                  src="/logo/logo-extended.svg"
                  class="logo-extended justify-end"
                  alt="Esegui il login Pellegrini"
                />
              </v-col>
            </v-row>
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent.stop="loginPellegrini"
            >
              <v-card class="elevation-0 px-0 px-sm-7 pb-5">
                <div
                  class="text-h1 line-height-1 text-center mt-6 mb-2"
                  style="color: #555555 text-transform: none;"
                >
                  Accedi inserendo le credenziali che utilizzi per il portale
                  Pellegrini&#174;
                </div>
                <v-spacer></v-spacer>
                <v-card-text>
                  <v-text-field
                    color="primary"
                    v-model="username"
                    :error-messages="errors"
                    type="text"
                    label="Username"
                    autocomplete="off"
                    class="mb-2"
                    required
                    dense
                    outlined
                    clearable
                  ></v-text-field>
                  <v-text-field
                    color="primary"
                    v-model="password"
                    label="Password"
                    autocomplete="off"
                    :append-icon="showPassword ? '$eye' : '$eyeOff'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    @click:append="toggleShowPassword"
                    required
                    dense
                    outlined
                    clearable
                  ></v-text-field>

                  <div class="d-flex flex-column remember-password">
                    <v-btn
                      :loading="loadingPellegrini"
                      type="submit"
                      color="#9e0005"
                      large
                      block
                      depressed
                      class="white--text"
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                    <ResponseMessage :response="response" class="mt-3" />
                  </div>
                </v-card-text>
              </v-card>
            </v-form>
          </div>
        </div>
      </v-dialog>
      <v-btn
        v-else
        class="ml-2 edenred-button"
        v-on:click="login"
        depressed
        :disabled="reachMinimumPurchase && paymentType.paymentTypeId == 30"
      >
        <v-img
          style="width: 140px;"
          :src="
            '/img_layout/meal_ticket/btn_' + paymentType.paymentTypeId + '.svg'
          "
          :alt="'Logo Ticket Restaurant'"
        />
      </v-btn>
    </v-row>
    <v-row class="d-flex justify-center mt-5">
      <div
        class="authenticated"
        v-if="ticketData.logged || ticketData.giftCertificateConfirmed == true"
      >
        <div class="logout-panel row" v-if="ticketData.logged">
          <div class="col-6 img">
            <v-img
              class="logo-heading-image"
              :src="
                '/img_layout/meal_ticket/' + paymentType.paymentTypeId + '.svg'
              "
              :alt="'Logo' + paymentType.paymentName"
            />
          </div>

          <div
            class="col-6"
            v-if="ticketData.giftCertificateConfirmed == false"
          >
            Accesso a
            {{
              ticketData.paymentType.paymentTypeId == 30
                ? "UpDay"
                : ticketData.paymentType.paymentTypeId == 21
                ? "Pellegrini"
                : "Edenred"
            }}
            effettuato <br /><a
              @click="logout()"
              title="Disconnetti il tuo account"
              aria-label="Disconnetti il tuo account"
              class="logout-button clickable"
            >
              ESCI
            </a>
          </div>
        </div>

        <div class="max-payable justify-start">
          Puoi pagare fino a
          <strong>{{ $n(ticketData.maxAmount, "currency") }}</strong> della tua
          spesa con
          {{ $t("orders.ticketType." + ticketData.paymentType.paymentTypeId) }}
        </div>

        <div class="ticket-data-wrapper">
          <div class="row header">
            <div class="col-2">BUONO</div>
            <div class="col-8">QUANTIT&Agrave;</div>
            <div class="col-2">TOTALE</div>
          </div>
          <div class="row" v-for="(voucher, index) in vouchers" :key="index">
            <div class="col-2">{{ $n(voucher.value, "currency") }}</div>
            <div class="col-8">
              <div class="qty-wrapper">
                <button
                  v-if="ticketData.paymentType.paymentTypeId != 30"
                  class="decrement-button"
                  @click="decrement(voucher)"
                  :disabled="
                    forceDisabledChangeQuantity ||
                      voucher.currentCount == 0 ||
                      ticketData.giftCertificateConfirmed == true
                  "
                >
                  -
                </button>
                <span class="qty">{{ voucher.currentCount }}</span>
                <button
                  class="increment-button"
                  @click="increment(voucher)"
                  v-if="ticketData.paymentType.paymentTypeId != 30"
                  :disabled="
                    forceDisabledChangeQuantity ||
                      voucher.currentCount == voucher.maxCount ||
                      ticketData.giftCertificateConfirmed == true
                  "
                >
                  +
                </button>
              </div>
            </div>
            <div class="col-2">
              {{ $n(voucher.value * voucher.currentCount, "currency") }}
            </div>
          </div>
          <div class="row footer-ticket">
            <div class="col-2">Totale</div>
            <div class="col-8"></div>
            <div class="col-2">
              {{ $n(grossTotal, "currency") }}
            </div>
          </div>
        </div>

        <div class="total"></div>
        <v-row class="d-flex justify-end pt-4 ">
          <v-col cols="12" md="6" class="d-flex justify-end">
            <v-btn
              v-if="ticketData.paymentType.paymentTypeId == 30"
              class="primary"
              @click="editUpdayQuantity()"
            >
              Modifica quantità
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.edenred-button {
  border: 2px solid #1d204c;
  border-radius: 10px;
  padding: 4px;
  min-width: 200px;
  min-height: 48px;
  display: inline-block;
  text-align: center;
  width: auto;
  img {
    height: 40px;
  }
}

.white-background {
  background-color: $white !important;
}

.ticket-type-restaurant {
  .descr {
    font-size: 16px;
  }
}
.logout-panel {
  max-width: 500px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.69);
  [class^="col-"],
  [class*=" col-"] {
    padding: 10px;
  }
  .img {
    .logo-heading-image {
      text-align: right;
      width: 100px;
    }
  }
}
.logout-button {
  text-decoration: underline;
  color: $primary;
  font-weight: bold;
  font-size: 12px;
}
.authenticated {
  width: 100%;
}
.max-payable {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ticket-data-wrapper {
  max-width: 500px;
  margin: auto;
  font-weight: 800;
  [class^="col-"],
  [class*=" col-"] {
    padding: 8px;
    text-align: center;
  }
  .row {
    border-bottom: 1px solid -var(grey-lighten2);
    &:last-child {
      border-bottom-color: transparent;
    }
  }
  .header {
    color: -var(grey-lighten1);
    font-weight: bold;
  }
  .footer-ticket {
    background: transparent;
    color: -var(grey-lighten1);
    font-weight: bold;
  }
  .qty-wrapper {
    border: 1px solid -var(grey-lighten2);
    border-radius: 4px;
    display: inline-flex;
    .increment-button {
      background-color: transparent;
      border: none;
      border-left: 1px solid -var(grey-lighten2);
      padding: 4px 12px;
    }
    .qty {
      min-width: 80px;
      padding: 4px 12px;
    }
    .decrement-button {
      background-color: transparent;
      border: none;
      border-right: 1px solid -var(grey-lighten2);
      padding: 4px 12px;
    }
  }
  .v-dialog {
    overflow: hidden;
  }
}
.custom-warning .wrn {
  background-color: white !important;
  color: var(--v-error-base);
  border-color: var(--v-error-base) !important;
  border-width: 2px;
  border-style: solid;
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import MealTicketService from "~/service/mealTicketService";

import {
  handleRedirectEdenred,
  payment,
  handleLogoutEdenred
} from "./gatewayUtilsService";

import gateway from "~/mixins/gateway";

import { mapActions, mapGetters, mapState } from "vuex";

import { requiredValue } from "~/validator/validationRules";

export default {
  name: "TicketTypeRestaurant",
  mixins: [gateway],
  data() {
    return {
      loadingPellegrini: false,
      loading: false,
      username: "",
      showPassword: null,
      password: "",
      error: null,
      selectedPaymentAuthId: null,
      ticketData: {},
      response: {},
      pellegrini: {},
      userPellegrini: "",
      pwdPellegrini: "",
      dialog: false,
      valid: true,
      lazy: true,
      passwordRules: [requiredValue("Digitare la password")],
      errors: [],
      invalid: null,
      forceDisabledChangeQuantity: false
    };
  },
  components: { ResponseMessage },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    vouchers() {
      return this.ticketData &&
        this.ticketData.data &&
        this.ticketData.data.vouchers
        ? this.ticketData.data.vouchers
        : [];
    },
    grossTotal() {
      return this.ticketData.data ? this.ticketData.data.grossTotal : null;
    },
    reachMinimumPurchase() {
      return (
        this.ticketData.minimumPurchase > 0 &&
        this.cart.totalPrice < this.ticketData.minimumPurchase
      );
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    async editUpdayQuantity() {
      let urlLoginData = await MealTicketService.editUpdayQuantity(
        this.paymentType.paymentTypeId
      );
      payment(urlLoginData.redirectUrl, null, "get");
      this.forceDisabledChangeQuantity = true;
    },
    async login() {
      this.loading = true;
      try {
        await this.getCart();
        if (window.cordova) {
          if (this.paymentType.paymentTypeId == 21) {
            global.EventBus.$emit("pellegrini:login");
          }
          //TODO APP
          handleRedirectEdenred(this.ticketData.redirectUrl);
        } else {
          if (this.paymentType.paymentTypeId == 21) {
            global.EventBus.$emit("pellegrini:login");
            this.forceDisabledChangeQuantity = false;
          } else if (this.paymentType.paymentTypeId == 30) {
            let urlLoginData = await MealTicketService.upDayLogin(
              this.paymentType.paymentTypeId
            );
            payment(urlLoginData.redirectUrl, null, "get");
            this.forceDisabledChangeQuantity = true;
          } else {
            // window.location = this.ticketData.redirectUrl;
            payment(this.ticketData.redirectUrl, null, "get");
            this.forceDisabledChangeQuantity = false;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.push("/checkout");
    },
    async getDetail() {
      if (this.paymentType.paymentTypeId == 21) {
        this.ticketData = await MealTicketService.getPellegrini(
          this.paymentType.paymentTypeId
        );
        this.forceDisabledChangeQuantity = false;
      } else if (
        this.paymentType.paymentTypeId == 18 ||
        this.paymentType.paymentTypeId == 19
      ) {
        this.ticketData = await MealTicketService.getEdenred(
          this.paymentType.paymentTypeId
        );
        this.forceDisabledChangeQuantity = false;
      } else if (this.paymentType.paymentTypeId == 30) {
        this.ticketData = await MealTicketService.getUpDay(
          this.paymentType.paymentTypeId
        );
        this.forceDisabledChangeQuantity = true;
      }

      this.getCart();
    },
    async increment(voucher) {
      if (this.forceDisabledChangeQuantity) {
        return;
      }

      voucher.currentCount = voucher.currentCount + 1;
      if (this.ticketData.paymentType.paymentTypeId == 21) {
        this.ticketData = await MealTicketService.updatePellegrini(
          this.ticketData.paymentType.paymentTypeId,
          this.ticketData.data
        );
      } else {
        this.ticketData = await MealTicketService.updateEdenred(
          this.ticketData.paymentType.paymentTypeId,
          this.ticketData.data
        );
      }
      global.EventBus.$emit("edenred-update");

      this.getCart();
    },
    async decrement(voucher) {
      if (this.forceDisabledChangeQuantity) {
        return;
      }

      voucher.currentCount = voucher.currentCount - 1;
      if (this.ticketData.paymentType.paymentTypeId == 21) {
        this.ticketData = await MealTicketService.updatePellegrini(
          this.ticketData.paymentType.paymentTypeId,
          this.ticketData.data
        );
      } else {
        this.ticketData = await MealTicketService.updateEdenred(
          this.ticketData.paymentType.paymentTypeId,
          this.ticketData.data
        );
      }
      global.EventBus.$emit("edenred-update");
      this.getCart();
    },
    async logout() {
      if (window.cordova) {
        //TODO APP
        if (this.ticketData.paymentType.paymentTypeId == 30) {
          MealTicketService.logoutUpDay();
          global.EventBus.$emit("upday:logout");
        } else if (this.ticketData.paymentType.paymentTypeId == 21) {
          MealTicketService.logoutPellegrini();
          global.EventBus.$emit("pellegrini:logout");
        } else {
          handleLogoutEdenred(this.ticketData.logout);
        }
      } else {
        if (this.ticketData.paymentType.paymentTypeId == 30) {
          MealTicketService.logoutUpDay();
          window.location = this.ticketData.logout;
        } else if (this.ticketData.paymentType.paymentTypeId == 21) {
          await MealTicketService.logoutPellegrini();
          window.location = this.ticketData.logout;
        } else {
          window.location = this.ticketData.logout;
        }
        this.getCart();
      }
    },
    async loginPellegrini() {
      var _this = this;
      this.loadingPellegrini = true;
      if (!_this.pellegrini) {
        _this.pellegrini = {};
      }

      if (_this.password.length > 0) {
        try {
          _this.ticketData = await MealTicketService.loginPellegrini(
            _this.username,
            _this.password
          );
          _this.loadingPellegrini = false;
          _this.$emit("submit", true);
          _this.response = _this.ticketData.response;
          if (_this.ticketData.response.status == 0) {
            global.EventBus.$emit("edenred:update");
            _this.pellegrini.showLogin = false;
            window.location = _this.ticketData.data.redirectUrl;
          }
        } catch (response) {
          _this.response = response;
        } finally {
          _this.loadingPellegrini = false;
        }

        this.getCart(true);
      }
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    }
  },
  mounted() {
    global.EventBus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },
  created() {
    if (this.paymentType.logged) {
      this.ticketData = this.paymentType;
      this.getDetail();
    } else {
      this.getDetail();
    }
  }
};
</script>
